/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://ql3tco2uzrdzzlhz4p4tyctpry.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lyeceg65gfhltdctuzezvyqgxu",
    "aws_cognito_identity_pool_id": "us-east-1:c2199b19-7916-484a-91d4-dde961d45cf9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UjwM2Ck4O",
    "aws_user_pools_web_client_id": "3mavc2spa00hcq0fqc5hb8mnba",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
